import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { NewProductSlider } from "src/app/shared/data/slider";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "app-product-box-three",
  templateUrl: "./product-box-three.component.html",
  styleUrls: ["./product-box-three.component.scss"],
})
export class ProductBoxThreeComponent implements OnInit {
  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() cartModal: boolean = false; // Default False
  @Input() title: string = "Offers"; // Default
  onHowerChangeImage: boolean = true; // Default False
  @Input() type: string = "fashion";
  public products: Product[] = [];
  public NewProductSliderConfig: any = NewProductSlider;
  public ImageSrc: string;
  Loader = false;
  constructor(
    private productService: ProductService,
    private fire: AngularFirestore
  ) {}

  ngOnInit(): void {
    this.getproducts();
  }

  getproducts() {
    this.Loader = true;
    this.fire
      .collection("Services", (ref) =>
        ref.where("serviceType", "==", "wooden-doors").limit(10)
      )
      .valueChanges()
      .subscribe(
        (res) => {
          this.products = res;
          this.Loader = false;
        },
        (err) => {
          this.Loader = false;
        }
      );
  }
}
