// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  firebaseConfig: {
    apiKey: "AIzaSyD1suObnVsPS7mu-D2_eKir-23wCJ_HBM4",
    authDomain: "teddington-admin.firebaseapp.com",
    projectId: "teddington-admin",
    storageBucket: "teddington-admin.appspot.com",
    messagingSenderId: "967255661050",
    appId: "1:967255661050:web:2828d26e1b62fb740a2c43",
    measurementId: "G-JM90Q36BGM",
  },

  stripe_token: "STRIPE_TOKEN",
  paypal_token: "PAYPAL_TOKEN",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
