import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, startWith, delay, last } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Product } from "../classes/product";
import { AngularFirestore } from "@angular/fire/compat/firestore";

const state = {
  products: JSON.parse(localStorage["products"] || "[]"),
  wishlist: JSON.parse(localStorage["wishlistItems"] || "[]"),
  compare: JSON.parse(localStorage["compareItems"] || "[]"),
  cart: JSON.parse(localStorage["cartItems"] || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public Currency = { name: "Dollar", currency: "USD", price: 1 }; // Default Currency
  public OpenCart: boolean = false;
  public Products;
  totalItems = [];
  showLoadMore = false;
  lastRecord: Product;
  limit = 10;
  constructor(
    private toastrService: ToastrService,
    private fire: AngularFirestore
  ) {}

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */
  showSuccess(message) {
    this.toastrService.success(message);
  }
  // Product
  get products(): Observable<any[]> {
    if (this.totalItems.length) {
      this.lastRecord = this.totalItems[this.totalItems.length - 1];
    } else {
      this.lastRecord = null;
    }

    let data = this.fire
      .collection(
        "Products",
        (ref) => ref.orderBy("createdAt", "desc")
        // this.lastRecord
        //   ? ref
        //       .orderBy("createdAt", "desc")
        //       .startAfter(this.lastRecord)
        //       .limit(this.limit)
        //   : ref.orderBy("createdAt", "desc").limit(this.limit)
      )
      .valueChanges()
      .pipe(
        map((data: any[]) => {
          localStorage["products"] = JSON.stringify(data);
          this.totalItems = data;
          if (data.length == this.limit) {
            this.showLoadMore = true;
          } else {
            this.showLoadMore = false;
          }
          return this.totalItems;
        })
      );

    return data;
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<any> {
    return this.fire
      .collection("Products", (ref) => ref.where("id", "==", slug))
      .valueChanges();
  }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  getFilteredProducts(filter, next?): Observable<Product[]> {
    if (next) {
      if (this.totalItems.length) {
        this.lastRecord = this.totalItems[this.totalItems.length - 1];
      } else {
        this.lastRecord = null;
      }
    } else {
      this.totalItems = [];
    }

    if (Object.keys(filter).length == 1) {
      let data = this.fire
        .collection("Products", (ref) =>
          this.lastRecord
            ? ref
                .startAfter(this.lastRecord)

                .where(
                  Object.keys(filter)[0],
                  "==",
                  filter[Object.keys(filter)[0]]
                )
            : ref.where(
                Object.keys(filter)[0],
                "==",
                filter[Object.keys(filter)[0]]
              )
        )
        .valueChanges()
        .pipe(
          map((data: any[]) => {
            localStorage["products"] = JSON.stringify(data);
            this.totalItems = data;
            if (data.length == this.limit) {
              this.showLoadMore = true;
            } else {
              this.showLoadMore = false;
            }
            return this.totalItems;
          })
        );

      return data;
    }
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {
    if (payload === "ascending") {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "a-z") {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "z-a") {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "low") {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "high") {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      });
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 16
  ) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
