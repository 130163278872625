<!-- side-bar single product slider start -->
<div class="theme-card">
  <h5 class="title-border">{{ title }}</h5>
  <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
    <ng-container>
      <ng-template carouselSlide>
        <div>
          <div
            class="d-flex align-items-center img-wrapper"
            *ngFor="let product of products | slice : 0 : 3"
          >
            <a
              [routerLink]="['/pages/services']"
              [queryParams]="{ services: product.serviceType }"
              class=""
            >
              <img
                class="slider-img"
                [defaultImage]="'assets/images/product/placeholder.jpg'"
                [lazyLoad]="product.imageUrl"
                alt=""
              />
            </a>

            <div class="media-body align-self-center">
              <div class="fw-bold py-2">
                {{ product.name }}
              </div>
              <!-- <a [routerLink]="['/shop/product', product?.id]">
                <h6>{{ product.type | titlecase }}</h6>
              </a> -->
            </div>
          </div>

          <div
            *ngIf="!products.length && !Loader"
            class="text-center py-5 my-5"
          >
            <h4>No Products to show !!</h4>

            <img
              class="m-auto"
              src="https://cdni.iconscout.com/illustration/premium/preview/no-product-8316266-6632286.png?f=avif&h=700"
              alt=""
            />
          </div>
          <div *ngIf="Loader" class="d-flex justify-content-center">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide *ngIf="products.length > 3">
        <div>
          <div
            class="d-flex align-items-center img-wrapper"
            *ngFor="let product of products | slice : 3 : 6"
          >
            <a
              [routerLink]="['/pages/services']"
              [queryParams]="{ services: product.serviceType }"
              class=""
            >
              <img
                class="slider-img"
                [defaultImage]="'assets/images/product/placeholder.jpg'"
                [lazyLoad]="product.imageUrl1"
                alt=""
              />
            </a>
            <!-- <a [routerLink]="['/shop/product', product.id]" class="back">
              <img
                class="slider-img"
                [defaultImage]="'assets/images/product/placeholder.jpg'"
                [lazyLoad]="product.imageUrl2"
                alt=""
              />
            </a> -->

            <div class="media-body align-self-center">
              <div class="fw-bold py-2">
                {{ product.name }}
              </div>
              <a [routerLink]="['/shop/product', product?.id]">
                <h6>{{ product.type | titlecase }}</h6></a
              >
            </div>
          </div>
          <div
            *ngIf="!products.length && !Loader"
            class="text-center py-5 my-5"
          >
            <h4>No Products to show !!</h4>

            <img
              class="m-auto"
              src="https://cdni.iconscout.com/illustration/premium/preview/no-product-8316266-6632286.png?f=avif&h=700"
              alt=""
            />
          </div>
          <div *ngIf="Loader" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
