import { Component, OnInit, Input, ViewChild } from "@angular/core";

import { Product } from "../../../classes/product";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "app-product-box-one",
  templateUrl: "./product-box-one.component.html",
  styleUrls: ["./product-box-one.component.scss"],
})
export class ProductBoxOneComponent implements OnInit {
  products: any = [];

  @Input() product: Product;
  @Input() currency: any; // Default Currency
  @Input() thumbnail: boolean = false; // Default False
  @Input() onHowerChangeImage: boolean = true; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;

  public ImageSrc: string;

  constructor(private fire: AngularFirestore) {}

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => {
        this.loader = false;
      }, 2000); // Skeleton Loader
    }
  }

  getproducts() {
    this.fire
      .collection("Products", (ref) =>
        ref.where("launchCategory", "==", "New").limit(10)
      )
      .valueChanges()
      .subscribe((res) => {
        this.products = res;
      });
  }

  // Get Product Color
}
