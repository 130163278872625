<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product?.launchCategory == 'New'">new</span>
      <span class="lable4 py-2" *ngIf="product?.launchCategory == 'Offers'"
        >In Offer</span
      >
    </div>
    <div class="front">
      <a [routerLink]="['/shop/product/', product?.id]">
        <img
          [defaultImage]="
            ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'
          "
          [lazyLoad]="ImageSrc ? ImageSrc : product?.imageUrl1"
          class="product-img lazy-loading"
          alt="{{ product?.name }}"
        />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/', product?.id]">
        <img
          [src]="ImageSrc ? ImageSrc : product?.imageUrl2"
          class="product-img lazy-loading"
          alt="{{ product?.name }}"
        />
      </a>
    </div>
    <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
      <li
        class="grid_thumb_img"
        [class.active]="ImageSrc == image?.src"
        *ngFor="let image of product?.images"
      >
        <a
          href="javascript:void(0)"
          (mouseover)="ChangeVariantsImage(image?.src)"
        >
          <img [lazyLoad]="image?.src" />
        </a>
      </li>
    </ul> -->
  </div>
  <div class="product-detail">
    <div>
      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
      <h5 class="fw-bold">{{ product?.name }}</h5>
      <a [routerLink]="['/shop/product/', product?.id]">
        <h6>{{ product?.category | titlecase }}</h6>
      </a>
    </div>
  </div>
</div>

<!-- <div *ngIf="!loader" ngFor="let product of products">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>
  </div>
</div> -->

<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
