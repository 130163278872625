<!--footer section -->
<footer [class]="class">
  <section class="section-b-space light-layout">
    <div class="container">
      <div class="row footer-theme partition-f">
        <div class="col-lg-4 col-md-6">
          <div class="footer-title footer-mobile-title">
            <h4>about</h4>
          </div>
          <div class="footer-contant">
            <div class="footer-logo">
              <!-- <img [src]="themeLogo" alt="logo" /> -->
              <div class="h3 fw-bold">Teddington Glazing</div>
            </div>
            <p>
              Welcome to Teddington Glazing , your one-stop destination for
              premium quality tiles and tile accessories. We specialize in
              providing a wide range of exquisite tiles that are both
              aesthetically pleasing and durable.
            </p>
            <div class="footer-social">
              <ul>
                <li>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-google-plus" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-twitter" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-instagram" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a href="javascript:void(0)"
                    ><i class="fa fa-rss" aria-hidden="true"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col offset-xl-1">
          <div class="sub-title">
            <div class="footer-title">
              <h4>Categories</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li><a href="javascript:void(0)">New</a></li>
                <li><a href="javascript:void(0)">UNIQUE </a></li>
                <li><a href="javascript:void(0)"> IN OFFER </a></li>
                <li><a href="javascript:void(0)">REDUCED ITEMS</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="sub-title">
            <div class="footer-title">
              <h4>visit</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li>
                  <a
                    href="javascript:void(0)"
                    routerLink="home
                  "
                    >Home</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/shop/products"
                    >Products</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/services"
                    >Services</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    routerLink="/pages/blog/no/sidebar"
                    >Blog</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/aboutus"
                    >About Us</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" routerLink="/pages/contact"
                    >Contact Us</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="sub-title">
            <div class="footer-title">
              <h4>store information</h4>
            </div>
            <div class="footer-contant">
              <ul class="contact-list">
                <li><i class="fa fa-map-marker"></i>Teddington Glazing</li>
                <li>
                  <i class="fa fa-phone"></i>Call Us: 02036599067, 07922241113
                </li>
                <li>
                  <i class="fa fa-envelope-o"></i>Email Us:
                  <a>Support@XYZ.com</a>
                </li>
                <li><i class="fa fa-fax"></i>Fax: 123XXX</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="sub-footer">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <p>
              <i class="fa fa-copyright" aria-hidden="true"></i>
              {{ today | date : "y" }}
            </p>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="payment-card-bottom">
            <ul>
              <li>
                <a><img src="assets/images/icon/visa.png" alt="" /></a>
              </li>
              <li>
                <a><img src="assets/images/icon/mastercard.png" alt="" /></a>
              </li>
              <li>
                <a><img src="assets/images/icon/paypal.png" alt="" /></a>
              </li>
              <li>
                <a
                  ><img src="assets/images/icon/american-express.png" alt=""
                /></a>
              </li>
              <li>
                <a><img src="assets/images/icon/discover.png" alt="" /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!--footer section end -->
