<!-- side-bar single product slider start -->
<div class="theme-card">
  <h5 class="py-3 m-0 text-center">{{ title }}</h5>
  <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
    <ng-container>
      <ng-template carouselSlide>
        <div>
          <div *ngFor="let product of products | slice : 0 : 1">
            <div class="img-wrapper">
              <div class="">
                <a
                  [routerLink]="['/pages/services']"
                  [queryParams]="{ services: product.serviceType }"
                >
                  <img
                    class="container-img w-100 lazy-loading"
                    [defaultImage]="'assets/images/product/placeholder.jpg'"
                    [lazyLoad]="product.imageUrl"
                    alt="{{ product.name }}"
                  />
                </a>
              </div>
              <!-- <div class="back">
                <a [routerLink]="['/shop/product', product.id]">
                  <img
                    class="container-img w-100 lazy-loading"
                    [src]="ImageSrc ? ImageSrc : product.imageUrl2"
                    alt="{{ product.name }}"
                  />
                </a>
              </div> -->
            </div>
            <div class="media-body align-self-center p-3">
              <div class="fw-bold py-2">
                {{ product.name }}
              </div>
              <!-- <a [routerLink]="['/pages/services', product.serviceType]">
                <h6>{{ product.type | titlecase }}</h6>
              </a> -->
            </div>

            <div class="text-center mt-4 pt-3">
              <a
                [routerLink]="['/pages/services']"
                [queryParams]="{ services: product.serviceType }"
                class="btn btn-solid rounded-pill"
              >
                See More
              </a>
            </div>
          </div>

          <div
            *ngIf="!products.length && !Loader"
            class="text-center py-5 my-5"
          >
            <h4>No Products to show !!</h4>

            <img
              class="m-auto"
              src="https://cdni.iconscout.com/illustration/premium/preview/no-product-8316266-6632286.png?f=avif&h=700"
              alt=""
            />
          </div>
          <div *ngIf="Loader" class="d-flex justify-content-center">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide *ngIf="products.length > 1">
        <div>
          <div *ngFor="let product of products | slice : 1 : 2">
            <div class="img-wrapper">
              <div class="">
                <a
                  [routerLink]="['/pages/services']"
                  [queryParams]="{ services: product.serviceType }"
                >
                  <img
                    class="container-img w-100 lazy-loading"
                    [defaultImage]="'assets/images/product/placeholder.jpg'"
                    [lazyLoad]="product.imageUrl"
                    alt="{{ product.name }}"
                  />
                </a>
              </div>
              <!-- <div class="back">
                <a
                  [routerLink]="['/pages/services']"
                  [queryParams]="{ services: product.serviceType }"
                >
                  <img
                    class="container-img w-100 lazy-loading"
                    [src]="ImageSrc ? ImageSrc : product.imageUrl2"
                    alt="{{ product.name }}"
                  />
                </a>
              </div> -->
            </div>
            <div class="media-body align-self-center p-3">
              <div class="fw-bold py-2">
                {{ product.name }}
              </div>
              <!-- <a [routerLink]="['/pages/services', product.serviceType]">
                <h6>{{ product.type | titlecase }}</h6>
              </a> -->
            </div>

            <div class="text-center mt-4 pt-3">
              <a
                [routerLink]="['/pages/services']"
                [queryParams]="{ services: product.serviceType }"
                class="btn btn-solid rounded-pill"
              >
                See More
              </a>
            </div>
          </div>

          <div
            *ngIf="!products.length && !Loader"
            class="text-center py-5 my-5"
          >
            <h4>No Products to show !!</h4>

            <img
              class="m-auto"
              src="https://cdni.iconscout.com/illustration/premium/preview/no-product-8316266-6632286.png?f=avif&h=700"
              alt=""
            />
          </div>
          <div *ngIf="Loader" class="d-flex justify-content-center">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </ng-template>

      <ng-template carouselSlide *ngIf="products.length > 2">
        <div>
          <div *ngFor="let product of products | slice : 2 : 3">
            <div class="img-wrapper">
              <div class="">
                <a
                  [routerLink]="['/pages/services']"
                  [queryParams]="{ services: product.serviceType }"
                >
                  <img
                    class="container-img w-100 lazy-loading"
                    [defaultImage]="'assets/images/product/placeholder.jpg'"
                    [lazyLoad]="product.imageUrl"
                    alt="{{ product.name }}"
                  />
                </a>
              </div>
              <div class="back">
                <a
                  [routerLink]="['/pages/services']"
                  [queryParams]="{ services: product.serviceType }"
                >
                  <img
                    class="container-img w-100 lazy-loading"
                    [src]="ImageSrc ? ImageSrc : product.imageUrl2"
                    alt="{{ product.name }}"
                  />
                </a>
              </div>
            </div>
            <div class="media-body align-self-center p-3">
              <div class="fw-bold py-2">
                {{ product.name }}
              </div>
              <!-- <a [routerLink]="['/pages/services', product.serviceType]">
                <h6>{{ product.type | titlecase }}</h6>
              </a> -->
            </div>

            <div class="text-center mt-4 pt-3">
              <a
                [routerLink]="['/pages/services']"
                [queryParams]="{ services: product.serviceType }"
                class="btn btn-solid rounded-pill"
              >
                See More
              </a>
            </div>
          </div>

          <div
            *ngIf="!products.length && !Loader"
            class="text-center py-5 my-5"
          >
            <h4>No Products to show !!</h4>

            <img
              class="m-auto"
              src="https://cdni.iconscout.com/illustration/premium/preview/no-product-8316266-6632286.png?f=avif&h=700"
              alt=""
            />
          </div>
          <div *ngIf="Loader" class="d-flex justify-content-center">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
