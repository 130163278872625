import { Component, OnInit, Input } from "@angular/core";
import { NewProductSlider } from "../../../data/slider";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Component({
  selector: "app-product-box-vertical-slider",
  templateUrl: "./product-box-vertical-slider.component.html",
  styleUrls: ["./product-box-vertical-slider.component.scss"],
})
export class ProductBoxVerticalSliderComponent implements OnInit {
  @Input() title: string = "New Product"; // Default
  @Input() type: string = "fashion"; // Default Fashion
  Loader = false;
  @Input() onHowerChangeImage: boolean = true; // Default False

  public products: Product[] = [];

  public NewProductSliderConfig: any = NewProductSlider;

  public ImageSrc: string;

  constructor(
    public productService: ProductService,
    private fire: AngularFirestore
  ) {
    // this.productService.getProducts.subscribe(
    //   (response) =>
    //     (this.products = response.filter((item) => item.type == this.type))
    // );
  }

  ngOnInit(): void {
    this.getproducts();
  }
  getproducts() {
    this.Loader = true;
    this.fire
      .collection("Services", (ref) =>
        ref.where("serviceType", "==", "upvc-doors").limit(10)
      )
      .valueChanges()
      .subscribe((res) => {
        this.products = res;
        console.log(this.products);
        this.Loader = false;
      });
  }
}
