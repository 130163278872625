import { Injectable, HostListener } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  megaMenu?: boolean;
  image?: string;
  active?: boolean;
  badge?: boolean;
  badgeText?: string;
  children?: Menu[];
  isVisible?: boolean;
  value?: string;
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  constructor() {}

  public screenWidth = window.innerWidth;
  public leftMenuToggle: boolean = false;
  public mainMenuToggle: boolean = false;

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      title: "home",
      type: "link",
      path: "/home",
      active: true,
    },
    // {
    //   title: "Products",
    //   type: "link",
    //   path: "/shop/products",
    //   // active: false,
    // },

    {
      title: "Doors",
      type: "sub",
      active: false,
      // type: "link",
      // path: "/pages/services",
      children: [
        {
          title: " UPVC Doors",
          path: "/pages/services",
          type: "link",
          value: "upvc-doors",
        },
        {
          title: " Composite Doors",
          path: "/pages/services",
          type: "link",
          value: "composite-doors",
        },
        {
          title: " Aluminum Doors",
          path: "/pages/services",
          type: "link",
          value: "aluminum-doors",
        },
        {
          title: " Wooden Doors",
          path: "/pages/services",
          type: "link",
          value: "wooden-doors",
        },
      ],
    },
    {
      title: "Window",
      type: "sub",
      active: false,
      // path: "/pages/services",
      // value: "Windows",
      children: [
        {
          title: " UPVC Windows",
          path: "/pages/services",
          type: "link",
          value: "upvc-windows",
        },
        {
          title: " Aluminum Windows",
          path: "/pages/services",
          type: "link",
          value: "aluminum-windows",
        },
        {
          title: " Wooden Windows",
          path: "/pages/services",
          type: "link",
          value: "wooden-windows",
        },
      ],
    },

    {
      title: "Conservatory",
      type: "sub",
      path: "/pages/services",
      // value: "Other Services",
      active: false,
      children: [
        {
          title: " UPVC Conservatory",
          path: "/pages/services",
          type: "link",
          value: "upvc-conservatory",
        },
        {
          title: " Aluminum Conservatory",
          path: "/pages/services",
          type: "link",
          value: "aluminum-conservatory",
        },
      ],
    },
    {
      title: "Other services",
      type: "sub",
      // path: "/pages/services",
      // value: "Other Services",
      active: false,
      children: [
        {
          title: "Glass Installation & Products",
          path: "/pages/services",
          type: "link",
          value: "glass-installation-products",
        },
        {
          title: "  Step Unit",
          path: "/pages/services",
          type: "link",
          value: "step-unit",
        },
        {
          title: " Flat Roof light & Sky Pot",
          path: "/pages/services",
          type: "link",
          value: "flat-roof-light-sky-pot",
        },
        {
          title: " Repairing of UPVC , Aluminum windows and doors",
          path: "/pages/services",
          type: "link",
          value: "repairing-of-upvc-aluminum-windows-and-doors",
        },
        {
          title: "  Installation of cat flap and day flap",
          path: "/pages/services",
          type: "link",
          value: "installation-of-cat-flap-and-day-flap",
        },
        {
          title: "Metal Gate",
          path: "/pages/services",
          type: "link",
          value: "tiles-products",
        },
      ],
    },
    {
      path: "/pages/projects",
      title: "Projects",
      type: "link",
    },
    { path: "/pages/aboutus", title: "About us", type: "link" },
    // {
    //   path: "/pages/catalog",
    //   title: "catalogs",
    //   type: "link",
    // },
    {
      path: "/pages/contact",
      title: "Get a free quote",
      type: "link",
      isVisible: this.screenWidth > 700,
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
