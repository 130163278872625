import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { NewProductSlider } from "src/app/shared/data/slider";

@Component({
  selector: "app-product-box-five",
  templateUrl: "./product-box-five.component.html",
  styleUrls: ["./product-box-five.component.scss"],
})
export class ProductBoxFiveComponent implements OnInit {
  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() thumbnail: boolean = false; // Default False
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False

  public ImageSrc: string;
  public products: Product[] = [];
  @Input() title: string = "Offers"; // Default
  @Input() type: string = "fashion"; // Default Fashion
  Loader = false;
  public NewProductSliderConfig: any = NewProductSlider;

  constructor(
    private productService: ProductService,
    private fire: AngularFirestore
  ) {}

  // Get Product Color
  ngOnInit(): void {
    this.getproducts();
  }
  getproducts() {
    this.Loader = true;
    this.fire
      .collection("Services", (ref) =>
        ref.where("serviceType", "==", "aluminum-doors")
      )
      .valueChanges()
      .subscribe(
        (res) => {
          this.products = res;
          this.Loader = false;
        },
        (err) => {
          this.Loader = false;
        }
      );
  }
}
