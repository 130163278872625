<!--footer section -->
<footer [ngClass]="class">
  <div class="dark-layout" *ngIf="mainFooter">
    <div class="container">
      <section class="section-b-space border-b">
        <div class="row footer-theme2">
          <div class="col-lg-4">
            <!-- <div class="footer-title footer-mobile-title">
              <h4>about</h4>
            </div> -->
            <div class="footer-contant">
              <div class="footer-logo" id="footerlogo">
                <h4>Teddington Glazing</h4>

                <img [src]="themeLogo" style="border-radius: 0px !important" alt="logo" height="70px" />
              </div>
              <!-- <p>
                Our selection of tile spacers and leveling systems are designed
                to help you achieve perfectly spaced and level tiles every time.
              </p> -->
            </div>
          </div>
          <div class="col-lg-4">
            <div class="footer-title">
              <h4>visit</h4>
            </div>

            <div class="footer-contant">
              <div>
                <a href="javascript:void(0)" routerLink="home
                      ">Home</a>
              </div>
              <!-- <div>
                <a href="javascript:void(0)" routerLink="/shop/products"
                  >Products</a
                >
              </div> -->
              <!-- <div>
                <a href="javascript:void(0)" routerLink="/pages/services"
                  >Services</a
                >
              </div> -->
              <div>
                <a href="javascript:void(0)" routerLink="/pages/projects">Projects</a>
              </div>
              <div>
                <a href="javascript:void(0)" routerLink="/pages/aboutus">About Us</a>
              </div>
              <!-- <div>
                <a href="javascript:void(0)" routerLink="/pages/catalog"
                  >Catalogs</a
                >
              </div> -->
              <div>
                <a href="javascript:void(0)" routerLink="/pages/contact">Contact Us</a>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="footer-title">
              <h4>store information</h4>
            </div>
            <div class="footer-contant">
              <ul class="contact-details">
                <li>
                  Address: 143,Stanley road,Teddington TW118UF, London. UK
                </li>
                <li>Call Us: 02036599067, 07922241113</li>
                <li>
                  Email Us:
                  <a href="javascript:void(0)">Teddingtonglazing@gmail.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- <div class="dark-layout" *ngIf="subFooter">
    <div class="container">
      <section class="small-section">
        <div class="row footer-theme2">
          <div class="col p-set">
            <div class="footer-link">
              <div class="sub-title">
                <div class="footer-title">
                  <h4>Categories</h4>
                </div>
                <div class="footer-contant">
                  <ul>
                    <li><a href="javascript:void(0)">Womens Fashion</a></li>
                    <li><a href="javascript:void(0)">Mens Fashion</a></li>
                    <li><a href="javascript:void(0)">Kids Fashion</a></li>
                    <li><a href="javascript:void(0)">Featured</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="footer-link-b">
              <div class="sub-title">
                <div class="footer-title">
                  <h4>why we choose</h4>
                </div>
                <div class="footer-contant">
                  <ul>
                    <li><a href="javascript:void(0)">shipping & return</a></li>
                    <li><a href="javascript:void(0)">secure shopping</a></li>
                    <li><a href="javascript:void(0)">gallary</a></li>
                    <li><a href="javascript:void(0)">affiliates</a></li>
                    <li><a href="javascript:void(0)">contacts</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div> -->
  <div class="sub-footer darker-subfooter">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-6 col-md-6 col-sm-12 text-center">
          <div class="footer-end">
            <p>
              <i class="fa fa-copyright" aria-hidden="true"></i>
              {{ today | date : "y" }} rights reseved by Teddington Glazing &
              Tiles
            </p>
          </div>
        </div>

        <p class="col text-center">
          Made with ❤️ by
          <a href="https://angriotechnologies.com/" target="_blank" style="color: rgb(47, 144, 255)">Angrio
            Technologies</a>
        </p>
      </div>
    </div>
  </div>
</footer>
<!--footer section end -->